import React, { useState, useEffect }  from "react"
import { Link } from "gatsby"
import { ChevronUp, ChevronDown } from "react-feather"

import Formlayout from "../components/formlayout.js"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/style.sass"
import "../styles/formlayout.css"

const Questionnaire = () =>{

  const [iconColor, iconColor_Change] = useState("white")
  const [iconColorD, iconColor_ChangeD] = useState("white")

  const [queError,queErrorChange] = useState(" ")
  // Question.map((content) => {
  //   const [(buttonvis + content.queNO, buttonappear + content.queNO] = useState({ display: "none" })
    
  // })
    

  const Question = [
    {
      queNo: 1,
      que: "Enter Name",
    },
    {
      queNo: 2,
      que: "Enter E-mail address.",
    },
    {
      queNo: 3,
      que: "Enter phone no.",
    },
    {
      queNo: 4,
      que: "State your first preferred talent that you love to showcase always",
    },
    {
      queNo: 5,
      que: "State your Second preferred talent that you love to showcase always",
    },
    {
      queNo: 6,
      que: "State your Third preferred talent that you love to showcase always",
    },
    {
      queNo: 7,
      que: "State your Mother’s maiden Name?",
    },
    {
      queNo: 8,
      que: "State your body Weight?",
    },
    {
      queNo: 9,
      que: "State the exact time of your birth.",
    },
    {
      queNo: 10,
      que: "State the primary set of skills, in that you are very good.",
    },
    {
      queNo: 11,
      que: "State the secondary set of skills, in that you are very good.",
    },
    {
      queNo: 12,
      que: "State the tertiary set of skills, in that you are very good.",
    },
    {
      queNo: 13,
      que: "State the primary ability, through that you are getting / can be paid for.",
    },
    {
      queNo: 14,
      que: "State the secondary ability, through that you are getting / can be paid for.",
    },
    {
      queNo: 15,
      que: "State the tertiary ability, through that you are getting / can be paid for.",
    },
    {
      queNo: 16,
      que: "Given name of your Father.",
    },
    {
      queNo: 17,
      que: "State the exact place and country of your birth.",
    },
    {
      queNo: 18,
      que: "Your level at your organization / business / intuition.",
    },
    {
      queNo: 19,
      que: "State your body Height",
    },
  ]

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const [que1, que2, que3, que4, que5, que6, que7, que8, que9, que10, que11, que12, que13, que14, que15, que16, que17, que18, que19,] = evt.target.elements

    const data = JSON.stringify({
      slug: que1.value,
      que1: que1.value,
      que2: que2.value,
      que3: que3.value,
      que4: que4.value,
      que5: que5.value,
      que6: que6.value,
      que7: que7.value,
      que8: que8.value,
      que9: que9.value,
      que10: que10.value,
      que11: que11.value,
      que12: que12.value,
      que13: que13.value,
      que14: que14.value,
      que15: que15.value,
      que16: que16.value,
      que17: que17.value,
      que18: que18.value,
      que19: que19.value,
    })

    fetch("https://blogend.liveabsolute.org/wp/wp-json/wp/v2/questionnaire", {
      method: 'post',
      headers: {
        'Authorization': 'Basic '+btoa('dev:devdev'),
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.ok === true) {
          // Submitted successfully!
        }
        else {
          queErrorChange ("Try again") 
          
        }
  
        return response.json()
      })
      .then((object) => {
        // Comment submission failed.
        // Output `object.message` to see the error message.
        queErrorChange (object.message)
      })
      .catch(error => console.error('Error:', error))

      window.location.href = "/thanku/"
  } 

  // console.log(buttonvis)

  return(
    <div className="questionnaire">
      <SEO title="Portal" />
        <Formlayout>
          <form action="https://www.liveabsolute.org/" onSubmit={handleSubmit} method="post">
              {/*questions*/}
              {Question.map((content) => (
              <div className="sizer" id={content.queNo}>
                <div className="input">
                  <label for="name">{ content.queNo + ". " + content.que}</label>
                  <input type="text"  name={'que' + content.queNo} id={'que' + content.queNo} required></input>
                  <div className="navbutton">
                  {
                    content.queNo > 1
                    &&
                    <Link to={"/questionnaire/#" + (content.queNo - 1)}>
                        <input type="button" value="Previous" ></input>
                  </Link>}
                  <Link to={"/questionnaire/#" + (content.queNo + 1)}>
                        <input type="button" value="Next" ></input>
                  </Link>
                  </div>
                </div>
              </div>
              ))}
              {/* Button */}
              <div className="sizer" id="20">
                <div className="input">
                    <label for="name">That's everything.</label>
                    <label for="name">We hope you're excited as we are :)</label>
                    <p>{queError}</p>
                    <div className="navbutton" >
                      <Link to="/questionnaire/#1"><input type="button" value="Review"></input></Link>
                      <input type="submit" value="Submit"></input>
                    </div>
                </div>
              </div>
              {/* <input type="submit" value="Submit"></input>  */}
          </form> 
          {/* BottomNav */}
          {/* <div className="nav-bottom">
            <div className="queNav">
              <Link to="/questionnaire/#3" ><ChevronUp className="buttonarrow" onMouseOver={ () =>{ iconColor_Change("black")}} onMouseOut={() => {iconColor_Change("white")}} color={iconColor} size={30}></ChevronUp></Link>
              <Link to="/questionnaire/#2" ><ChevronDown className="buttonarrow" onMouseOver={ () =>{ iconColor_ChangeD("black")}} onMouseOut={() => {iconColor_ChangeD("white")}} color={iconColorD} size={30} /></Link>
              <p style={{ color: 'white'}}>Answered 0 of 13</p>
            </div>
          </div> */}
        </Formlayout>
    </div>
  )
} 

export default Questionnaire